import React from "react";
import { buildClassName } from "../../utils";

interface ScheduleAnchorsProps {
  itinerary: {
    id: string;
    name?: string;
    title?: string;
    description?: string;
  }[];
  currentIndex?: number;
  onAnchorClick?: (index: number) => void;
}

const ScheduleAnchors: React.FC<ScheduleAnchorsProps> = ({
  itinerary,
  currentIndex,
  onAnchorClick,
}) => (
  <div className="schedule__anchors">
    {itinerary.map((segment, i) => (
      <a
        key={`schedule-${segment.id}`}
        onClick={(e) => {
          e.preventDefault();

          if (onAnchorClick) {
            onAnchorClick(i);
          }
        }}
        title={segment.name}
        className={buildClassName([
          "schedule__anchor",
          currentIndex === i && "schedule__anchor--active",
        ])}
      >
        <p className="schedule__anchor-copy">
          <span className="schedule__anchor-heading">{segment.name}</span>
          {segment.title}
        </p>
      </a>
    ))}
  </div>
);

export default ScheduleAnchors;
