import { filter, head, map } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  TideItemForLinkedExcursion,
  TideItemForLinkedHotel,
  TideItemForPage,
} from "../../types";
import { STICKY_BAR_HEIGHT } from "./constants";
import ItineraryCarousel from "./itinerary-carousel";
import Schedule from "./schedule";
import Segment from "./segment";

interface ItineraryProps {
  productName: string;
  title: string;
  introduction: string;
  slides: {
    src: string;
  }[];
  itinerary?: {
    id: string;
    index?: number;
    name?: string;
    title?: string;
    description?: string;
    location?: {
      latitude?: number;
      longitude?: number;
      zoom?: number;
    };
    images?: Image[];
    embedCode?: string;
    caption?: string;
    linkedHotels?: TideItemForLinkedHotel[];
    linkedExcursions?: TideItemForLinkedExcursion[];
  }[];
  pricingComponent?: React.ReactElement;
  linkComponent?: React.ReactElement;
  itineraryIndex?: number;
  onAnchorClick?: (index: number) => void;
  agenciesPath?: string;
  contactPath?: string;
}

const Itinerary: React.FC<ItineraryProps> = ({
  productName,
  title,
  introduction,
  slides,
  itinerary,
  pricingComponent,
  linkComponent,
  agenciesPath,
  contactPath,
}) => {
  const [itineraryIndex, setItineraryIndex] = useState(0);
  const itineraryRef = useRef<HTMLDivElement>(null);

  const handleScroll: EventListener = () => {
    if (itineraryRef.current) {
      const currentIndex = head(
        filter(
          map(itineraryRef.current.querySelectorAll(".itinerary__section"), (element, index) => ({
            top: element.getBoundingClientRect().top,
            index,
          })),
          (element) => element.top >= STICKY_BAR_HEIGHT - 2
        )
      )?.index;

      if (currentIndex !== undefined) {
        setItineraryIndex(currentIndex);
      }
    }
  };

  const handleAnchorClick = (index: number) => {
    const linkedElement = document.getElementById(`itinerary-part-${index}`);
    if (linkedElement) {
      window.scrollTo({
        behavior: "smooth",
        top: window.scrollY + linkedElement.getBoundingClientRect().top - STICKY_BAR_HEIGHT + 1,
      });
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  });

  return (
    <div className="itinerary">
      <div className="itinerary__inspiration">
        <ItineraryCarousel title={title} introduction={introduction} slides={slides} />
      </div>

      <div className="itinerary__timeline">
        <div className="itinerary__sections" ref={itineraryRef}>
          {(itinerary ?? []).map((segment, i) => (
            <Segment
              key={`timeline-${segment.id}`}
              index={i}
              name={segment.name}
              description={segment.description}
              title={segment.title}
              images={segment.images}
              caption={segment.caption}
              embedCode={segment.embedCode}
              linkedHotels={segment.linkedHotels}
              linkedExcursions={segment.linkedExcursions}
            />
          ))}
        </div>
        <Schedule
          agenciesPath={agenciesPath}
          contactPath={contactPath}
          productName={productName}
          itineraryIndex={itineraryIndex}
          itinerary={itinerary ?? []}
          linkComponent={linkComponent}
          pricingComponent={pricingComponent}
          onAnchorClick={handleAnchorClick}
        />
      </div>
    </div>
  );
};

export default Itinerary;
