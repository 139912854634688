import React, { ChangeEventHandler } from "react";
import { buildClassName } from "../utils";

interface CheckboxProps {
  checked?: boolean;
  label?: string;
  count?: string;
  reversed?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, label, count, reversed, onChange }) => (
  <label className={buildClassName(["checkbox", reversed && "checkbox--reverse"])}>
    <input
      type="checkbox"
      name="checkbox-filter"
      className="checkbox__input"
      checked={checked}
      onChange={onChange}
    />
    {label && <span>{label}</span>}
    {count && <em className="amount-label">{count}</em>}
  </label>
);

export default Checkbox;
