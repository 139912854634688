import React from "react";
import { setImageParameters } from "../utils";

interface ExpertProps {
  title?: string;
  description?: JSX.Element;
  thumbnailUrl?: string;
}

const Expert: React.FC<ExpertProps> = ({ title, description, thumbnailUrl, children }) => {
  return (
    <div className="expert">
      <div className="expert__content">
        {thumbnailUrl && (
          <div className="image">
            <img
              src={setImageParameters(thumbnailUrl, { width: 75, height: 75, mode: "crop" })}
              className="image__content"
            />
          </div>
        )}
        <h5 className="expert__heading">{title}</h5>
        {description && <div className="expert__description">{description}</div>}
      </div>
      {children}
    </div>
  );
};

export default Expert;
