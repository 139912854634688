import React, { useEffect, useState } from "react";
import { buildClassName } from "../../utils";
import { BODY_MODIFIER } from "./constants";
import Checkbox from "../checkbox";
import ScheduleMap from "./schedule-map";
import ScheduleAnchors from "./schedule-anchors";
import { useMediaQuery } from "beautiful-react-hooks";
import Expert from "../expert";
import Link from "../link";
import { Image } from "../../types";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

export interface ScheduleProps {
  productName: string;
  itinerary: {
    id: string;
    name?: string;
    title?: string;
    description?: string;
    location?: {
      latitude?: number;
      longitude?: number;
      zoom?: number;
    };
    images?: Image[];
  }[];
  pricingComponent?: React.ReactElement;
  linkComponent?: React.ReactElement;
  itineraryIndex?: number;
  onAnchorClick?: (index: number) => void;
  agenciesPath?: string;
  contactPath?: string;
}

const Schedule: React.FC<ScheduleProps> = ({
  productName,
  itinerary,
  pricingComponent,
  linkComponent,
  itineraryIndex,
  onAnchorClick,
  agenciesPath,
  contactPath,
}) => {
  const [active, setActive] = useState(false);
  const [mapMode, setMapMode] = useState(false);
  const { t } = useI18next();

  const isLargeMedia = typeof window === "undefined" ? true : useMediaQuery(`(min-width: 1200px)`);

  useEffect(() => {
    document.body.classList.toggle(BODY_MODIFIER, active && !isLargeMedia);

    return () => {
      document.body.classList.remove(BODY_MODIFIER);
    };
  }, [active, isLargeMedia]);

  return (
    <>
      <div
        className={buildClassName(["backdrop", active && !isLargeMedia && "backdrop--visible"])}
        onClick={() => setActive(false)}
      />
      <div
        className={buildClassName([
          "itinerary__schedule",
          active && !isLargeMedia && "itinerary__schedule--active",
        ])}
      >
        <div className="schedule">
          <h3 className="schedule__heading" onClick={() => setActive(!active)}>
            {t("TRAVELSCHEME")}
          </h3>
          <div className="schedule__mode-toggle">
            <Checkbox
              label={t("SHOW_MAP_VIEW")}
              checked={mapMode}
              onChange={() => setMapMode(!mapMode)}
            />
          </div>
          {mapMode ? (
            <ScheduleMap
              itinerary={itinerary}
              currentIndex={itineraryIndex}
              onMarkerClick={onAnchorClick}
            />
          ) : (
            <ScheduleAnchors
              itinerary={itinerary}
              currentIndex={itineraryIndex}
              onAnchorClick={onAnchorClick}
            />
          )}
          <Expert
            title={t("WE_LOVE_TO_HELP_YOU")}
            description={
              <p>
                <Trans i18nKey="SEND_US_A_MESSAGE_OR_FIND_AGENT">
                  Stuur ons{" "}
                  <Link
                    href={`/${contactPath}?subject=${productName}`}
                    internal
                    title={t("SEND_US_A_MESSAGE")}
                    className="link"
                  >
                    een berichtje
                  </Link>
                  , of{" "}
                  <Link
                    href={`/${agenciesPath}`}
                    internal
                    title={t("FIND_YOUR_AGENT")}
                    className="link"
                  >
                    vind je reisexpert in de buurt
                  </Link>
                  .
                </Trans>
              </p>
            }
          >
            <div className="expert__pricing-cta">
              {pricingComponent}
              {linkComponent}
            </div>
          </Expert>
        </div>
      </div>
    </>
  );
};

export default Schedule;
