import Carousel from "nuka-carousel";
import React, { useState } from "react";
import { buildClassName } from "../../utils";
import { useI18next } from "gatsby-plugin-react-i18next";

export interface ItineraryCarouselProps {
  title: string;
  introduction: string;
  slides: {
    src: string;
  }[];
}

const ItineraryCarousel: React.FC<ItineraryCarouselProps> = ({ title, introduction, slides }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handleNextClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setSlideIndex((slideIndex + 1) % slides.length);
  };

  const handlePreviousClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setSlideIndex(slideIndex === 0 ? slides.length - 1 : slideIndex - 1);
  };

  const { t } = useI18next();

  return (
    <div className="carousel">
      <div className="carousel__items">
        <Carousel withoutControls slideIndex={slideIndex} afterSlide={(i) => setSlideIndex(i)}>
          {slides.map((slide, i) => (
            <div className="carousel__item" key={`itinerary-slide-${i}-${slide.src}`}>
              <img src={slide.src} alt={t("IN_VIEW")} className="carousel__item-media" />
            </div>
          ))}
        </Carousel>
      </div>
      <div className="carousel__frame">
        <div className="carousel__frame-content">
          <div className="carousel__controls">
            <button
              type="button"
              className="button button--arrow button--arrow-left"
              onClick={handlePreviousClick}
            ></button>
            <button
              type="button"
              className="button button--arrow button--arrow-right"
              onClick={handleNextClick}
            ></button>
          </div>
          <h2 className="carousel__frame-heading">{title}</h2>
          <div
            className="carousel__frame-description"
            dangerouslySetInnerHTML={{ __html: introduction ?? "" }}
          />
          <div className="carousel__indicators">
            {slides.map((slide, i) => (
              <div
                onClick={() => setSlideIndex(i)}
                key={`itinerary-carousel-dot-${i}-${slide.src}`}
                className={buildClassName([
                  "carousel__indicator",
                  slideIndex === i && "carousel__indicator--active",
                ])}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItineraryCarousel;
