import { compact, head, isEmpty } from "lodash";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { getMinStay } from "../../app/search-results/utils/search-util";
import {
  Image as ImageType,
  TideItemForLinkedExcursion,
  TideItemForLinkedHotel,
  TideItemForHotel,
  TideItemForExcursion,
} from "../../types";
import { setImageParameters } from "../../utils";
import Embed from "../embed";
import Image from "../image";
import RelatedProductCard from "../related-product-card";
import { useI18next } from "gatsby-plugin-react-i18next";

interface SegmentProps {
  index?: number;
  name?: string;
  title?: string;
  description?: string;
  images?: ImageType[];
  embedCode?: string;
  caption?: string;
  linkedHotels?: TideItemForLinkedHotel[];
  linkedExcursions?: TideItemForLinkedExcursion[];
}

const Segment: React.FC<SegmentProps> = ({
  index,
  name,
  title,
  description,
  images,
  embedCode,
  caption,
  linkedHotels,
  linkedExcursions,
}) => {
  const [imageIndex, setImageIndex] = useState<number | undefined>(undefined);
  const { t } = useI18next();
  return (
    <div className="itinerary__section" id={`itinerary-part-${index}`}>
      <h3 className="itinerary__section-heading">{name}</h3>
      <div className="itinerary__section-description">
        <h4>{title}</h4>
        <div
          dangerouslySetInnerHTML={{ __html: description ?? "" }}
          className="itinerary__section-description-content"
        />
        {images && images.length > 0 && (
          <>
            <Image
              type="golden-ratio"
              onLinkClick={() => setImageIndex(0)}
              src={setImageParameters(head(images)?.url as string, {
                width: 1140,
                height: 800,
                mode: "crop",
              })}
              count={images.length >= 2 ? images.length - 1 : undefined}
            />
            {typeof imageIndex !== "undefined" && (
              <Lightbox
                mainSrc={images[imageIndex].url ?? ""}
                nextSrc={images[(imageIndex + 1) % images.length]?.url ?? ""}
                prevSrc={images[(imageIndex + images.length - 1) % images.length]?.url ?? ""}
                onCloseRequest={() => setImageIndex(undefined)}
                onMovePrevRequest={() =>
                  setImageIndex((imageIndex + images.length - 1) % images.length)
                }
                onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
              />
            )}
          </>
        )}
        {!isEmpty(embedCode) && <Embed embedCode={embedCode ?? ""} aspectRatio="16-9" />}
        {!isEmpty(caption) && <p className="caption">{caption}</p>}
        {linkedHotels && linkedHotels.length > 0 && (
          <>
            <h4>Hotels</h4>
            <div className="productcard-grid productcard-grid--2">
              <div className="productcard-grid__items">
                {linkedHotels.map((linkedHotel) => {
                  const hotel = linkedHotel.content?.general?.hotel as TideItemForHotel;
                  return (
                    <RelatedProductCard
                      key={linkedHotel.id}
                      rating={hotel.content?.general?.stars ?? undefined}
                      productName={hotel.content?.general?.title ?? ""}
                      destinationName={hotel.content?.general?.product?.country?.name ?? undefined}
                      description={hotel.content?.general?.introduction ?? undefined}
                      headerImage={hotel.content?.general?.headerImage ?? undefined}
                      images={compact(hotel.content?.general?.images)}
                      price={{
                        defaultPrice: hotel.content?.general?.estimatedPrice ?? undefined,
                        promoPrice: hotel.content?.general?.estimatedPromoPrice ?? undefined,
                      }}
                      minStay={getMinStay(t, hotel, undefined)}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
        {linkedExcursions && linkedExcursions.length > 0 && (
          <>
            <h4>Excursies</h4>
            <div className="productcard-grid productcard-grid--2">
              <div className="productcard-grid__items">
                {linkedExcursions.map((linkedExcursion) => {
                  const excursion = linkedExcursion.content?.general
                    ?.excursion as TideItemForExcursion;
                  return (
                    <RelatedProductCard
                      key={linkedExcursion.id}
                      productName={excursion.content?.general?.title ?? ""}
                      destinationName={
                        excursion.content?.general?.product?.country?.name ?? undefined
                      }
                      description={excursion.content?.general?.introduction ?? undefined}
                      summary={excursion.content?.general?.summary ?? undefined}
                      headerImage={excursion.content?.general?.headerImage ?? undefined}
                      images={compact(excursion.content?.general?.images)}
                      isExcursion={true}
                      price={{
                        defaultPrice: excursion.content?.general?.estimatedPrice ?? undefined,
                        promoPrice: excursion.content?.general?.estimatedPromoPrice ?? undefined,
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Segment;
