import {
  TideAccommodation,
  TideItemForContactForm,
  TideItemForCountry,
  TideItemForDestinations,
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForNotificationComponentConnection,
  TideItemForOfferFormComponent,
  TideItemForPage,
  TideItemForQuote,
  TideItemForRegion,
  TideItemForRoundtrip,
  TideItemForUsp,
  TideItemForUspGroup,
  TideItemForWebsite,
  TideRegime,
} from "../types";
import { compact, first, isEmpty, isNil, some } from "lodash";
import {
  generatePath,
  getChildNavigationLinks,
  getItinerarySegments,
  getLinkedExcursions,
  getLinkedHotels,
  mapMinDurationType,
} from "../utils";

import Breadcrumb from "../components/breadcrumb";
import Footer from "../components/footer";
import GalleryGrid from "../components/gallery-grid";
import Hero from "../components/hero";
import HighlightFrame from "../components/highlight-frame";
import Itinerary from "../components/itinerary";
import Layout from "../components/layout";
import { LookupQueryData } from "../components/qsm";
import Navbar from "../components/navbar";
import PageComponents from "../components/page-components";
import ProductInfo from "../components/product-info";
import ProductIntro from "../components/product-intro";
import Quote from "../components/quote";
import React from "react";
import Section from "../components/section";
import StickyBar from "../components/sticky-bar";
import UspGrid from "../components/usp-grid";
import { graphql } from "gatsby";
import OfferForm from "../components/offer-form";
import { QSMStoreProvider } from "../app/search-results/qsm-store/context";
import { initialQSMState, qsmReducer } from "../app/search-results/qsm-store/reducer";
import { initialProductState, productReducer } from "../app/search-results/product-store/reducer";
import { ProductStoreProvider } from "../app/search-results/product-store/context";
import StickyBarActions from "../components/sticky-bar/actions";
import ProductPrice from "../components/pricing/product-price";
import BookButton from "../components/itinerary/book-button";
import RoomFlyIn from "../components/fly-in/fly-in-rooms";
import FlightsFlyIn from "../components/fly-in/fly-in-flights";
import Seo from "../components/seo";
import NotificationBar from "../components/notification-bar";
import { AlternateRoute } from "../../.gatsby/gatsby-node";
import { useI18next } from "gatsby-plugin-react-i18next";

interface PageTemplateProps {
  data: RoundtripPageData;
  pageContext: {
    alternateRoutes: AlternateRoute[];
  };
}

const RoundtripPageTemplate: React.FC<PageTemplateProps> = ({ data, pageContext }) => {
  const notificationBar = first(data.notifications?.nodes);
  const countryOrRegion = data.roundtrip.parentItem as TideItemForCountry | TideItemForRegion;
  const generalRoundtripContent = data.roundtrip?.content?.general;
  const { t } = useI18next();

  const parentCountry =
    countryOrRegion.parentItem?.templateName === "Country"
      ? (countryOrRegion.parentItem as TideItemForCountry)
      : undefined;
  const destinationsItem = parentCountry
    ? (parentCountry?.parentItem?.parentItem as TideItemForDestinations)
    : (countryOrRegion?.parentItem?.parentItem as TideItemForDestinations);

  const destinationsPath = generatePath(destinationsItem);
  const countryPath = parentCountry ? generatePath(parentCountry, destinationsPath) : "";
  const countryOrRegionPath = generatePath(
    countryOrRegion,
    countryPath === "" ? destinationsPath : countryPath
  );
  const roundtripPath = generatePath(data.roundtrip, countryOrRegionPath);

  const title = compact([
    generalRoundtripContent?.titlePrefix,
    generalRoundtripContent?.title,
  ]).join(" ");
  const highlights = generalRoundtripContent?.highlights?.split(/[;\r\n]+/);

  const quoteContent = (data.roundtrip.content?.general?.quote as TideItemForQuote)?.content
    ?.general;

  const hasOfferForm = (generalRoundtripContent?.product?.accommodations ?? []).length > 0;

  return (
    <QSMStoreProvider
      initialState={{
        ...initialQSMState,
        product: data.roundtrip,
        duration: generalRoundtripContent?.duration ?? undefined,
      }}
      reducer={qsmReducer}
    >
      <ProductStoreProvider
        initialState={{
          ...initialProductState,
          estimatedPrice: {
            defaultPrice: generalRoundtripContent?.estimatedPrice ?? undefined,
            promoPrice: generalRoundtripContent?.estimatedPromoPrice ?? undefined,
          },
        }}
        reducer={productReducer}
      >
        <Layout>
          <Seo
            title={title}
            seoTitle={data.roundtrip.content?.seo?.seoTitle ?? undefined}
            seoDescription={data.roundtrip.content?.seo?.seoDescription ?? undefined}
            seoKeywords={data.roundtrip.content?.seo?.seoKeywords ?? undefined}
          />
          <RoomFlyIn product={data?.roundtrip} isRoundTrip={true} />
          <FlightsFlyIn
            destination={
              (!isEmpty(countryOrRegion.content?.general?.title)
                ? countryOrRegion.content?.general?.title
                : countryOrRegion.name) ?? ""
            }
          />
          {!isEmpty(notificationBar?.content?.general?.notification) && (
            <NotificationBar
              text={notificationBar?.content?.general?.notification ?? ""}
              icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
              backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
            />
          )}
          <Navbar
            alternateRoutes={pageContext.alternateRoutes}
            mainNavigationSection={data.mainNavigationSection}
            fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
            fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
            socialNavigationSection={data.socialNavigationSection}
            legalNavigationSection={data.legalNavigationSection}
            phone={data.website?.content?.contact?.phone ?? ""}
            destinations={data.navigationDestinations}
          />
          <StickyBar
            navigationLinks={getChildNavigationLinks(data.stickyNavigationSection)}
            actionsComponent={<StickyBarActions />}
          />
          <Hero
            type="product"
            title={generalRoundtripContent?.title ?? undefined}
            titlePrefix={generalRoundtripContent?.titlePrefix ?? undefined}
            imageSrc={generalRoundtripContent?.headerImage?.url ?? undefined}
          />
          <Breadcrumb
            items={compact([
              { id: "home", title: "Home", url: "/" },
              parentCountry && {
                id: "destinations",
                title:
                  (parentCountry?.parentItem?.parentItem as TideItemForDestinations).content
                    ?.general?.title ?? "",
                url: destinationsPath,
              },
              !parentCountry && {
                id: "destinations",
                title:
                  (countryOrRegion?.parentItem?.parentItem as TideItemForDestinations).content
                    ?.general?.title ?? "",
                url: destinationsPath,
              },
              parentCountry && {
                id: "country",
                title:
                  (!isEmpty(parentCountry.content?.general?.title)
                    ? parentCountry.content?.general?.title
                    : parentCountry.name) ?? "",
                url: countryPath,
              },
              {
                id: "countryOrRegion",
                title:
                  (!isEmpty(countryOrRegion.content?.general?.title)
                    ? countryOrRegion.content?.general?.title
                    : countryOrRegion.name) ?? "",
                url: countryOrRegionPath,
              },
              {
                id: "roundtrip",
                title:
                  (!isEmpty(generalRoundtripContent?.title)
                    ? generalRoundtripContent?.title
                    : data.roundtrip.name) ?? "",
                url: roundtripPath,
              },
            ])}
          />
          <Section>
            <ProductIntro
              title={generalRoundtripContent?.title ?? ""}
              introduction={generalRoundtripContent?.introduction ?? ""}
              introductionTitle={generalRoundtripContent?.introductionTitle ?? ""}
              destination={
                (!isEmpty(countryOrRegion.content?.general?.title)
                  ? countryOrRegion.content?.general?.title
                  : countryOrRegion.name) ?? ""
              }
              mediaComponent={
                <GalleryGrid
                  images={
                    generalRoundtripContent?.images?.map((image) => ({
                      src: image?.url ?? "",
                    })) ?? []
                  }
                />
              }
              pricingComponent={<ProductPrice loaderText={t("CREATING_ROUNDTRIP")} />}
            />
          </Section>
          {generalRoundtripContent?.usps && generalRoundtripContent.usps.length > 0 && (
            <Section>
              <UspGrid usps={generalRoundtripContent?.usps as TideItemForUsp[]} />
            </Section>
          )}
          {highlights &&
            highlights.length > 0 &&
            some(highlights, (highlight) => !isEmpty(highlight)) && (
              <Section>
                <HighlightFrame highlights={highlights} />
              </Section>
            )}
          <Section>
            <Itinerary
              agenciesPath={data.agencies.content?.general?.slug ?? undefined}
              contactPath={data.contact.content?.general?.slug ?? undefined}
              productName={generalRoundtripContent?.title ?? ""}
              title={data.roundtrip.content?.itinerary?.itineraryTitle ?? ""}
              introduction={data.roundtrip.content?.itinerary?.itineraryIntro ?? ""}
              slides={(
                data.roundtrip.content?.itinerary?.itineraryCarouselImages ?? []
              ).map((image) => ({ src: image?.url ?? "" }))}
              itinerary={
                getItinerarySegments(data.roundtrip).map((segment, index) => ({
                  id: segment.id ?? "",
                  index: index,
                  name: segment.content?.general?.day || segment.name, // segment.name should be phased out because it is not multi-lang
                  title: segment.content?.general?.title ?? undefined,
                  description: segment.content?.description?.text ?? undefined,
                  location: {
                    latitude: segment.content?.general?.location?.latitude ?? undefined,
                    longitude: segment.content?.general?.location?.longitude ?? undefined,
                    zoom: segment.content?.general?.location?.zoom ?? undefined,
                  },
                  images:
                    segment.content?.description?.images?.map((i) => ({
                      url: i?.url ?? undefined,
                    })) ?? [],
                  embedCode: segment.content?.description?.embedCode ?? undefined,
                  caption: segment.content?.description?.caption ?? undefined,
                  linkedHotels: getLinkedHotels(segment),
                  linkedExcursions: getLinkedExcursions(segment),
                })) ?? []
              }
              pricingComponent={<ProductPrice />}
              linkComponent={<BookButton hasOfferForm={hasOfferForm} />}
            />
          </Section>
          {!isNil(quoteContent) && (
            <Section>
              <Quote
                image={quoteContent?.image?.url ?? undefined}
                title={quoteContent?.title ?? undefined}
                body={quoteContent?.body ?? undefined}
                signature={quoteContent?.signature ?? undefined}
                author={quoteContent?.author ?? undefined}
                job={quoteContent?.job ?? undefined}
              />
            </Section>
          )}
          {(!isEmpty(data.roundtrip.content?.description?.inclusive) ||
            !isEmpty(data.roundtrip.content?.description?.exclusive) ||
            !isEmpty(data.roundtrip.content?.description?.travelworldExtras)) && (
            <Section>
              <ProductInfo
                title={t("ABOUT_THIS_JOURNEY")}
                blocks={[
                  {
                    title: t("INCLUDED"),
                    description: data.roundtrip.content?.description?.inclusive ?? undefined,
                  },
                  {
                    title: t("NOT_INCLUDED"),
                    description: data.roundtrip.content?.description?.exclusive ?? undefined,
                  },
                  {
                    title: t("EXTRAS"),
                    description:
                      data.roundtrip.content?.description?.travelworldExtras ?? undefined,
                  },
                ]}
              />
            </Section>
          )}
          <PageComponents componentItems={compact(data.roundtrip.childItems)} />
          {hasOfferForm && (
            <OfferForm
              confirmationEmailTemplate={
                data.offerForm?.content?.externalMail?.templateConfirmationMail?.tideId
              }
              emailMessage={data.offerForm?.content?.internMail?.message ?? undefined}
              emailSubject={data.offerForm?.content?.internMail?.subject ?? undefined}
              introText={data.offerFormComponent?.content?.general?.description ?? undefined}
              title={data.offerFormComponent?.content?.general?.title ?? undefined}
              newsletterTag={data.offerFormComponent?.content?.tags?.newsletterTag?.tideId}
              offerTag={data.offerFormComponent?.content?.tags?.offerTag?.tideId}
              productName={generalRoundtripContent?.product?.name ?? undefined}
              productCode={generalRoundtripContent?.product?.code ?? undefined}
              minDuration={generalRoundtripContent?.product?.minDuration ?? undefined}
              minDurationType={mapMinDurationType(
                generalRoundtripContent?.product?.minDurationType ?? undefined
              )}
              accommodations={
                generalRoundtripContent?.product?.accommodations as TideAccommodation[]
              }
              regimes={generalRoundtripContent?.product?.regimes as TideRegime[]}
            />
          )}

          <Footer
            footerNavigationSection={data.footerNavigationSection}
            socialNavigationSection={data.socialNavigationSection}
            legalNavigationSection={data.legalNavigationSection}
            address={data.website?.content?.contact?.address ?? ""}
            phone={data.website?.content?.contact?.phone ?? ""}
            email={data.website?.content?.contact?.email ?? ""}
            uspGroup={data.footerUspGroup}
            newsletterForm={data.newsletterForm}
          />
        </Layout>
      </ProductStoreProvider>
    </QSMStoreProvider>
  );
};

interface RoundtripPageData extends LookupQueryData {
  roundtrip: TideItemForRoundtrip;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  offerForm: TideItemForContactForm;
  offerFormComponent: TideItemForOfferFormComponent;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  stickyNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  footerUspGroup: TideItemForUspGroup;
  newsletterForm: TideItemForNewsletterForm;
  agencies: TideItemForPage;
  contact: TideItemForPage;
}

export const query = graphql`
  query RoundtripPageQuery($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    agencies: tideItemForPage(name: { eq: "Reisbureaus" }, language: { eq: $language }) {
      id
      content {
        general {
          slug
        }
      }
    }
    contact: tideItemForPage(name: { eq: "Contact" }, language: { eq: $language }) {
      id
      content {
        general {
          slug
        }
      }
    }
    roundtrip: tideItemForRoundtrip(id: { eq: $id }) {
      id
      content {
        general {
          title
          titlePrefix
          headerImage {
            url
          }
          introductionTitle
          introduction
          images {
            url
          }
          usps {
            ... on TideItemForUsp {
              id
              content {
                general {
                  icon
                  text
                }
              }
            }
          }
          product {
            tideId
            code
            accommodations {
              code
              id
              tideId
              name
              occupancies {
                maxAdults
                maxChildAge
                maxChildren
                maxPax
                minAdults
                minAge
                minChildAge
                minPax
              }
            }
            regimes {
              id
              tideId
              name
              code
              localizedNames {
                languageCode
                value
              }
            }
          }
          highlights
          quote {
            ... on TideItemForQuote {
              content {
                general {
                  title
                  body
                  signature
                  author
                  job
                  image {
                    url
                  }
                }
              }
            }
          }
          estimatedPrice
          estimatedPromoPrice
          duration
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
        description {
          inclusive
          exclusive
          travelworldExtras
        }
        itinerary {
          itineraryTitle
          itineraryIntro
          itineraryCarouselImages {
            url
          }
        }
      }
      ...pageComponentsFragment
      ...productPathFragment
      childItems {
        id
        name
        templateName
        ... on TideItemForItinerarySegment {
          content {
            general {
              title
              day
              location {
                latitude
                longitude
                searchValue
                zoom
              }
            }
            description {
              text
              embedCode
              images {
                url
              }
              caption
            }
          }
          childItems {
            id
            name
            templateName
            ... on TideItemForLinkedHotel {
              content {
                general {
                  hotel {
                    id
                    ... on TideItemForHotel {
                      name
                      content {
                        general {
                          title
                          introduction
                          headerImage {
                            url
                            altText
                            title
                          }
                          images {
                            url
                            altText
                            title
                          }
                          stars
                          estimatedPrice
                          estimatedPromoPrice
                          product {
                            country {
                              name
                            }
                            minDuration
                            minDurationType
                          }
                        }
                      }
                      ...productPathFragment
                    }
                  }
                }
              }
            }
            ... on TideItemForLinkedExcursion {
              content {
                general {
                  excursion {
                    id
                    ... on TideItemForExcursion {
                      name
                      content {
                        general {
                          title
                          introduction
                          summary
                          headerImage {
                            url
                          }
                          images {
                            url
                            altText
                            title
                          }
                          estimatedPrice
                          estimatedPromoPrice
                          product {
                            country {
                              name
                            }
                            minDuration
                            minDurationType
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
          email
          address
        }
      }
    }
    ...navigationDestinationsFragment
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    mainNavigationSection: tideItemForNavigationSection(
      name: { eq: "Main Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    contactNavigationSection: tideItemForNavigationSection(
      name: { eq: "Contact Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    stickyNavigationSection: tideItemForNavigationSection(
      name: { eq: "Sticky Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    footerNavigationSection: tideItemForNavigationSection(
      name: { eq: "Footer Navigation" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    footerUspGroup: tideItemForUspGroup(name: { eq: "Footer Usps" }, language: { eq: $language }) {
      ...uspGroupFields
    }

    newsletterForm: tideItemForNewsletterForm(
      name: { eq: "Newsletter" }
      language: { eq: $language }
    ) {
      content {
        externalMail {
          templateConfirmationMail {
            tideId
          }
        }
        contactDetails {
          tag {
            tideId
          }
        }
        form {
          privacyPage
        }
      }
    }

    offerForm: tideItemForContactForm(name: { eq: "Offer" }, language: { eq: $language }) {
      content {
        externalMail {
          templateConfirmationMail {
            id
            tideId
            name
          }
        }
        internMail {
          message
          subject
        }
      }
      id
      name
    }

    offerFormComponent: tideItemForOfferFormComponent(
      name: { eq: "Offer Product Form" }
      language: { eq: $language }
    ) {
      content {
        general {
          description
          title
        }
        tags {
          newsletterTag {
            tideId
            name
          }
          offerTag {
            tideId
            name
          }
        }
      }
      id
      name
    }
  }
`;

export default RoundtripPageTemplate;
