import React from "react";
import Map from "../map";

interface ScheduleMapProps {
  itinerary: {
    id: string;
    name?: string;
    description?: string;
    location?: {
      latitude?: number;
      longitude?: number;
      zoom?: number;
    };
  }[];
  currentIndex?: number;
  onMarkerClick?: (index: number) => void;
}

const ScheduleMap: React.FC<ScheduleMapProps> = ({ itinerary, currentIndex, onMarkerClick }) => {
  return (
    <div className="schedule__map" style={{ height: "400px", width: "100%" }}>
      <Map
        options={{
          draggableCursor: "default",
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: true,
        }}
        markers={itinerary.map((segment, i) => ({
          id: segment.id,
          lat: segment.location?.latitude ?? 0,
          lng: segment.location?.longitude ?? 0,
          title: segment.name,
          active: currentIndex === i,
        }))}
        waypoints={itinerary.map((segment) => ({
          id: segment.id,
          lat: segment.location?.latitude ?? 0,
          lng: segment.location?.longitude ?? 0,
        }))}
        onMarkerClick={(id) => {
          if (onMarkerClick) {
            onMarkerClick(itinerary.findIndex((segment) => segment.id === id));
          }
        }}
      />
    </div>
  );
};

export default ScheduleMap;
