import React from "react";
import { useProductStore } from "../../app/search-results/product-store/context";
import { handleAnchorClick } from "../../utils";
import Link from "../link";
import { useI18next } from "gatsby-plugin-react-i18next";

interface BookButtonProps {
  hasOfferForm?: boolean;
}

const BookButton: React.FC<BookButtonProps> = ({ hasOfferForm }) => {
  const [productState] = useProductStore();
  const { t } = useI18next();

  return productState?.price?.defaultPrice ? (
    <Link href="#booking" internal className="cta" onClick={handleAnchorClick}>
      {t("BOOK_NOW")}
    </Link>
  ) : hasOfferForm ? (
    <Link href="#offer-form" className="cta" onClick={handleAnchorClick}>
      {t("REQUEST_OFFER")}
    </Link>
  ) : null;
};

export default BookButton;
